import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { isArray } from "lodash";
import { gql, useQuery } from "@apollo/client";
import LoadableImage from "./LoadableImage";
import { Skeleton } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const QUERY = gql`
query GetQRCodeImage($id: uuid!) {
  qr_code_by_pk(id: $id) {
    public_url
  }
}
`;

interface QRCodeProps {
  id: string | string[];
  fallbackText?: string;
  size?: number;
  className?: string;
}

const QRCode = (p: QRCodeProps) => {
  const effectiveId = isArray(p.id) ? p.id[0] : p.id;
  const {data, loading, startPolling, stopPolling} = useQuery(QUERY, {variables: {id: effectiveId}, skip: !effectiveId});
  const url = data?.qr_code_by_pk?.public_url;

  useEffect(() => {
    if (url) {
      stopPolling();
    } else {
      startPolling(1000)
    }
  }, [url, startPolling, stopPolling]);

  const sizeCss = p.size ? css`
    text-align: center;
    width: ${p.size}px;
    height: ${p.size}px;
  ` : null;

  if (!effectiveId) {
    return <div css={sizeCss} className={p.className}>{p.fallbackText || "None"}</div>
  }
  if (loading) {
    return <Skeleton variant="rect" css={sizeCss} className={p.className}/>
  } else {
    if (url) {
      return <LoadableImage css={[sizeCss, css`background-color: inherit;`]} url={url} {...p}/>
    } else {
      return <CircularProgress css={sizeCss} className={p.className} size={p.size} color="secondary" />
    }
  }
}

export default QRCode;