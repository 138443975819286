import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";

const styles ={
  container: css``,
}

interface ObjectSummaryItemProps {
  label: string;
  value?: number;
}

export default function ObjectSummaryItem(p: ObjectSummaryItemProps) {
  return (<div css={styles.container}>
    <Typography>{p.label}: {p.value || '...'}</Typography>
  </div>);
}
