import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useSession } from "../../../../SessionContext";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import useQRCode, { QRCodeType } from "../../components/Scanner/useQRCode";
import useProjectLexiconName from "../../queries/ProjectLexiconName";
import { Redirect } from "react-router-dom";

const styles = {
  container: css`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

interface ExternalScanProps {
  code: string;
}

export default function ExternalScan(p: ExternalScanProps) {
  const [redirect, setRedirect] = useState("");
  const [error, setError] = useState(false);
  const { userId, sessionId } = useSession();
  const { data: lexiconInfo, loading } = useProjectLexiconName(sessionId, {
    skip: !sessionId,
  });

  const lexiconId = lexiconInfo?.project_by_pk?.lexicon?.id;
  const checkCode = useQRCode();

  useEffect(() => {
    if (sessionId && loading) {
      return;
    }
    if (!userId) {
      console.log('redirecting')
      setRedirect(`/login?q=${p.code}`);
      return;
    }
    checkCode(p.code)
      .then((result) => {
        switch (result.type) {
          case QRCodeType.project:
            setRedirect(`/app/project/${result.objectId}/samples`);
            break;
          case QRCodeType.sample:
            setRedirect(
              `/app/project/${result.projectId}/samples/${result.objectId}`
            );
            break;
          case QRCodeType.descriptor:
            setRedirect(`/app/descriptor/${result.objectId}`);
            break;
          case QRCodeType.lexicon:
            if (result.objectId === lexiconId) {
              setRedirect(`/app/project/${sessionId}/lexicon`);
            } else {
              setRedirect(`/app/lexicon/${result.objectId}`);
            }
            break;
          default:
            setError(true);
            break;
        }
      })
      .catch((e) => setError(true));
  }, [p.code, checkCode, lexiconId, userId, sessionId, loading]);

  return (
    <div css={styles.container}>
      {redirect ? <Redirect to={redirect} /> : null}
      {error ? (
        <div style={{ color: "white" }}>
          <Typography variant="h3">Invalid code</Typography>
          <Typography align="center" variant="subtitle1">Code: {p.code}</Typography>
          <Button fullWidth onClick={() => setRedirect('/app/welcome')} color="primary" variant="contained">Return to Scanner</Button>
        </div>
      ) : (
        <CircularProgress size={200} />
      )}
    </div>
  );
}
